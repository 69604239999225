.outer_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #f5f5f5;
    border-radius: 10px;
}

.form_container {
    height: 90%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    overflow: auto;
}

.header_container {
    text-align: center;
    margin-bottom: 20px;
}

.header {
    font-size: 24px;
    color: #333333;
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555555;
}

.form-select, .form-control {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

.form-select:focus, .form-control:focus {
    border-color: #007bff;
    outline: none;
}

.button_container {
    text-align: right;
    margin-bottom: 20px;
}

.button {
    background-color: #d9534f;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #c9302c;
}

.submit_button {
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit_button:hover {
    background-color: #4cae4c;
}

.user_interface_message_container {
    background-color: #ffdddd;
    border-left: 6px solid #f44336;
    color: #a94442;
    padding: 10px;
    margin-top: 20px;
    border-radius: 4px;
}

.success {
    background-color: #dff0d8;
    border-left: 6px solid #3c763d;
    color: #3c763d;
}

.error {
    background-color: #f2dede;
    border-left: 6px solid #a94442;
    color: #a94442;
}
