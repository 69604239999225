.outer_container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #f5f5f5;
    border-radius: 10px;
}

.form_account_report_container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 90%;
}

.header_container {
    text-align: center;
    margin-bottom: 20px;
}

.header {
    font-size: 24px;
    color: #333333;
}

.radiobox_container {
    margin-bottom: 20px;
}

.input_container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.radio_input {
    margin-right: 10px;
}

.label {
    font-weight: bold;
    color: #555555;
}

.dropdown_form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.options_list {
    width: 100%;
    margin-bottom: 20px;
}

.title {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
    color: #555555;
}

.dropdown {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    height: 150px;
    overflow-y: scroll;
}

.dropdown:focus {
    border-color: #007bff;
    outline: none;
}

.option {
    padding: 10px;
    background-color: #ffffff;
    border-bottom: 1px solid #cccccc;
}

.option:hover {
    background-color: #f1f1f1;
}

.button_container {
    text-align: center;
    margin-top: 20px;
}

.submit_button {
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit_button:hover {
    background-color: #4cae4c;
}
