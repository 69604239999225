.container {
    position: fixed;
    height: 5vh;
    width: 100%;
    background-color: #011601;
    /* border: 1px dashed red; */
    display: flex;
    justify-content: center;
    z-index: 1;
    caret-color: transparent;
}

.quarter {
    height: 100%;
    width: 24%;
    /* border: 1px dashed blue; */
    display: flex;
    align-items: center;
}

.half {
    height: 100%;
    width: 50%;
    /* border: 1px dashed orange; */
    display: flex;
    justify-content: space-evenly;
}

.logo {
    height: 70%;
}

.link_container {
    height: 100%;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    text-decoration: none;
    color: #f3fcf0;
    font-size:calc(12px + 1.0vw);

}

.link:hover {
    animation: linkHover .5s;
    color: #c1eebb;
}

.active {
    text-decoration: none;
    font-size:calc(12px + 1.2vw);
    color: #c1eebb;
    animation: linkSelect .15s;
}

@keyframes linkHover {
    from {color: #f3fcf0;}
    to {color: #c1eebb;}
}

@keyframes linkSelect {
    from {font-size: calc(12px + 1.0vw);
    to {font-size: calc(12px + 1.2vw);}}
}