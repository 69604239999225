.account_creation_form_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.activate_form {
    /* border: 2px dashed red; */
    height: 80%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.input_container {
    /* border: 1px dashed red; */
    height: 25%;
    width: 100%;
}

.input_container:focus {
    border-color: #256f24;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);}

.form-control:focus {
    border-color: #256f24;
    border: 2px solid #256f24;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);  }

.input_label {
    font-size: calc(12px + .75vw);
    font-weight: 500;
    width: 100%;
}

.input_bar {
    min-height: 20px;
    height: 50%;
    width: 100%;
    border: none;
    font-size: calc(12px + .5vw);
}

.password_button {
    background-color: #c3c1c7;
    border: 1px solid #c3c1c7;
    width: 80px;
    color: #0f0f0f;
    font-size: 18px;
    font-weight: 400;
}

.password_button:hover {
    color: #0f0f0f;
    border: 1px solid #888098;
    background-color: #888098;
    animation-name: buttonHover;
    animation-duration: .5s;
}

.activate_button_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activate_button {
    width: 45%;
    height: 10%;
    min-height: 40px;
    background-color: #f3fcf0;
    font-size: large;
    font-weight: 500;
}

.activate_button:hover {
    background-color: #d1fcc5;
    color: black;
    animation-name: buttonHover;
    animation-duration: .5s;
}

.error_message {
    /* border: 1px dashed blue; */
    height: 20%;
    width: 100%;
    font-size: calc(12px + .75vw);
    font-weight: bold;
    color: rgb(51, 43, 43);
    display: flex;
    justify-content: center;
    text-decoration: underline;
}