.invoice_history_container {
    display: flex;
    padding: 20px;
    height: 80%;
}

.invoices_list {
    flex: 1;
    margin-right: 20px; /* Space between lists */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-y: auto; /* Scroll for long lists */
}

.invoice_items {
    flex: 0.5; /* Smaller than the list of invoices */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-y: auto; /* Scroll for long lists */
    height: 300px; /* Fixed height for overflow */
}

/* Styling the scrollbar */
.invoice_items::-webkit-scrollbar {
    width: 8px;
}

.invoice_items::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.invoice_items::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.invoice_items::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.invoice_item {
    padding: 5px;
    margin-bottom: 5px;
    min-width: 400px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s;
}

.invoice_item:hover {
    background-color: #f0f0f0;
}

.invoice_item_details {
    padding: 5px;
    margin-top: 5px;
    max-width: 400px;
    background-color: #1f1f2c;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: none; /* Initially hidden */
}

.invoice_item.expanded .invoice_item_details {
    display: block; /* Show details when expanded */
}
