.outer_container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #f5f5f5;
    border-radius: 10px;
}

.form_container {
    height: 90%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
}

.header_container {
    text-align: center;
    margin-bottom: 20px;
}

.header {
    font-size: 24px;
    color: #333333;
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555555;
}

.form-select, .form-control {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

.form-select:focus, .form-control:focus {
    border-color: #007bff;
    outline: none;
}

.update_button {
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.update_button:hover {
    background-color: #4cae4c;
}
