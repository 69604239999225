.billing_container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

/* ... other styles ... */

.invoice_item {
    margin-bottom: 15px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.service_item {
    cursor: pointer;
    padding: 5px;
    margin: 5px 0;
    background: #16161D;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

/* Apply hover effect to invoice items */
.invoice_item:hover {
    background-color: #242627;
}

/* Override the hover effect for invoice items when a service item is hovered */
.invoice_item:hover .service_item:not(:hover) {
    background-color: #16161D;
}

/* Specific hover effect for service items */
.service_item:hover {
    background-color: #3d4447;
}

/* Style for selected invoice and its services */
.selected {
    background-color: #242627;
}

/* Highlight all service items when their parent invoice is selected */
.selected .service_item {
    background-color: #3d4447;
}

.payment_form {
    border-left: 1px solid #ccc;
    padding-left: 20px;
    width: 800px;
}
