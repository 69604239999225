.SERVICE_MGMT_container_outer {
    /* border: 1px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
}

.SERVICE_MGMT_container_inner {
    height: 90%;
    width: 90%;
    min-height: 600px;
    min-width: 1200px;
    display: flex;
    justify-content: space-evenly;
    /* border: 1px dashed orange; */
}

.account_details_container {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px dashed green; */
}