.activation_form_container {
    /* border: 1px solid red; */
    height: 90%;
    min-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}

.return_button_container {
    width: 90%;
    height: 10%;
    min-height: 30px;
    margin-bottom: calc(40px + .2vw);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.return_button {
    font-size: calc(12px + .5vw);
    font-weight: 400;
    min-width: 100px;
    height: 100%;
    min-height: 30px;
    background-color: #f3fcf0;
}

.return_button:hover {
    background-color: #d1fcc5;
    color: black;
}

.input_container {
    height: 60%;
    width: 100%;
    /* border: 1px dashed red; */
}

.input_container:focus {
    border-color: #256f24;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);}

.form-control:focus {
    border-color: #256f24;
    border: 2px solid #256f24;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);  }

.input_label {
    font-size: calc(12px + .75vw);
    font-weight: 500;
    width: 100%;
}

.input_bar {
    min-height: 20px;
    height: 50%;
    width: 100%;
    border: none;
    font-size: calc(12px + .5vw);
}

.activate_form {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* border: 1px dashed black; */
}

.activate_button_container {
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activate_button {
    width: 45%;
    height: 100%;
    min-height: 20px;
    background-color: #f3fcf0;
    font-size: calc(12px + .5vw);;
    font-weight: 400;
}

.activate_button:hover {
    background-color: #d1fcc5;
    color: black;
}

.error_message {
    font-size: 20px;
    font-weight: bold;
    color: rgb(37, 35, 35);
}