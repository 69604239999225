.outer_container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.backdrop {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmation_container {
    user-select: none;
    height: 600px;
    width: 500px;
    border-radius: 8px;
    background-color: #16161D;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;

    display: flex;
    flex-direction: column;
}

.header_container {
    height: 80px;
    width: 100%;
    border-bottom: 3px solid black;
}

.exit_icon_container {
    height: 100%;
    width: 95%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.exit_icon {
    font-size: 2.5rem;
}

.exit_icon:hover {
    cursor: pointer;
    color: #ccc;
    animation: exit_icon_anim .25s;
}

@keyframes exit_icon_anim {
    from {color: white}
    to {color: #ccc}
}

.content_container {
    flex-grow: 1;
    overflow-y: auto;
}

.warning_message_container {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.warning_message {
    height: auto;
    width: 90%;
    display: flex;
    justify-content: center;
    color: red;
    text-align: center;
}

.footer_container {
    height: 80px;
    width: 100%;

    /* border: 1px solid yellow; */
}

.footer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirm_button {
    font-size: 1.4rem;
    height: 50px;
    width: 120px;
    background-color: white;
    color: #16161D;
    border-radius: 5px;
}

.confirm_button:hover {
    color: #16161D;
    background-color: #ccc;
    animation: confirm_button_hover .25s;
}

@keyframes confirm_button_hover {
    from {background-color: white;}
    to {background-color: #ccc;}
}