.dashboard_container {
    background-color: #ffffff;
    color: #231F20;
    font-size: 20px;
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;

    /* border: 1px solid blue; */
}

.component_container {
    /* border: 1px solid red; */

    height: 93vh; /* pairs with ADMIN_HEADER */
    width: 100%;

    flex-grow: 1;
}

@media (min-width: 1600px) {
    .component_container {
        /* border: 1px solid red; */

        height: 93vh;
        width: 100%;

        flex-grow: 1;
    }
}

@media (max-width: 1600px) {
    .component_container {
        /* border: 1px solid red; */

        height: 93vh;
        width: 100%;

        flex-grow: 1;
    }
}