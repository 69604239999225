/* Base CSS */
.first-section-container {
    width: 100%;
    min-width: 1200px;
    height: 95vh;
    display: flex;
    justify-content: center;
    background-image: url('../../../../../Images/firstSectionBGShort2.jpg');
    background-size: cover;
}

.center_container {
    height: 85%;
    width: 50%;
    min-width: 900px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* border: 1px dashed red; */
}

.main_logo {
    height: 60%;
    width: 40%;
    min-width: 300px;
    min-height: 100px;
    /* border: 1px dashed blue; */
}

.logo-container {
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
    width: 100%;
    height: 25%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items:flex-end;
    font-size: 3em;
    font-style: italic;
}

/* Select Activate or Login Form */
.form-options-container-outer {
    width: 100%;
    height: 60%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    
    /* background-color: yellow; */
}

.form-options-container-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 128, 0, 0.363);
    backdrop-filter: blur(6px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    /* border: 1px solid blue; */
}

/* Form selected */
.form-container-outer {
    /* border: 1px solid blue; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
}

.form-container-inner {
    /* border: 1px solid black; */
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.return-button-container {
    height: 50%;
    width: 50%;
    /* border: 2px dashed black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.first_section_button {
    background-color: #f3fcf0;
    height: 25%;
    min-height: 50px;
    font-size:calc(12px + .75vw);
    width: 100%;
    font-weight: bold;
}

.first_section_button:hover {
    background-color: #d1fcc5;
    animation-name: buttonHover;
    animation-duration: .5s;
}

.go_to_login {
    background-color: #f3fcf0;
    font-size: calc(12px + .5vw);
    min-height: 30px;
    height: 8%;
}

.go_to_login:hover {
    background-color: #d1fcc5;
    animation-name: buttonHover;
    animation-duration: .5s;
}

@keyframes buttonHover {
    from {
        background-color: #f3fcf0;
        color: #231f20;}
    to {
        background-color: #d1fcc5;
        color: black;}
}

/* tablets / phones */
@media(max-width: 767px) {

}

@media(min-width: 768px) {
    
}