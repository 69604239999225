.accounts_container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.accounts_filter {
    width: 100%;
}

.account_square {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 10px;
    width: 250px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.account_square:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.account_square p {
    margin: 5px 0;
    font-size: 0.9em;
}
