/* Base CSS */
.main-page-container {
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.section-container {
    width: 100%;
}

/* tablet max-width */
@media (max-width: 767px) {

}

/* larger than tablet */
@media (min-width: 768px) {

}