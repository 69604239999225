.third_section_container {
    width: 100%;
    height: 80vh;
    background-color: #d8f1d5;
    font-size: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.placement {
    height: 90%;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.section_container {
    background-color: #09520f;
    border-radius:10px ;
    height: 100%;
    width: 40%;
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.075), 0 0 40px rgba(17, 51, 16, 0.6);
}