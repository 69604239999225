.CLIENT_ACCOUNT_MGMT_container_outer {
    /* border: 1px solid red; */

    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.CLIENT_ACCOUNT_MGMT_container_middle {
    height: 90%;
    width: 90%;
}

.CLIENT_ACCOUNT_MGMT_container_inner {
    height: 100%;
    width: 50%;
    /* border: 1px dashed red; */
    display: flex;
    justify-content: space-between;
}

.tabs_container_outer {
    /* border: 1px solid blue; */

    height: 100%;
    width: 33.3%;
    min-width: 300px;

    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;

}

.tabs_container_inner {
    background-color: white;
    height: 50%;
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);

}

.header_container {
    text-decoration: underline;
    font-weight: 500;
}

.tab {
    background-color: #888098;
    height: 40px;
    width: 90%;
    font-size: 1.2rem;
    /* font-weight: 500; */
}

.tab:hover {
    background-color: #b8b4c2;
}

.active {
    background-color: #b8b4c2;
    height: 55px;
    width: 90%;
    font-size: 1.2rem;
    font-weight: 400;
}

.active:hover {
    background-color: #b8b4c2;
}

.forms_container {
    /* border: 1px dashed purple; */
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: c;
}

.details_container {
    /* border: 1px solid blue; */

    height: 100%;
    width: 66.7%;
}

.CLIENT_ACCOUNT_MGMT_form_container {
    /* border: 1px solid red; */
}

.CLIENT_ACCOUNT_MGMT_form {
    display: flex;
    flex-direction: column;
}

.user_interface_message_container {
    font-style: italic;
    font-size: 20px;
}

.user_interface_message_container.error {
    color: red;
}

.user_interface_message_container.success {
    color: green;
}

/* MODIFY_ACCOUNTS_OUTSTANDING */
.collapsable_services_container {
    overflow-y: auto;
    max-height: 500px;
}

.collapsable_service {
    cursor: pointer;
    border-bottom: 2px solid #bdbdbd;
    border-radius: 5px;
}