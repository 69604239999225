.second_section_container {
    position: relative;
    max-width: 100%; /* Adjust as needed */
    margin: auto;
    overflow: hidden;
}

.section_container {
    height: 60vh;
    width: 100%;
    background-color: #09520f;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
