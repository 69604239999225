.component_title {
    /* border: 1px solid yellow; */

    display: flex;
    align-self: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.6rem;

    height: 100%;
    width: 1000px;
}

/* Base styles that apply to all devices */
.element {
    /* Common styles */
}

/* Tablet devices (portrait and landscape) */
@media (min-width: 768px) and (max-width: 1024px) {
    .element {
        /* Tablet-specific styles */
    }
}

/* Mobile devices */
@media (max-width: 767px) {
    .element {
        /* Mobile-specific styles */
    }
}

/* Desktop and larger screens */
@media (min-width: 1024px) {
    .element {
        /* Desktop-specific styles */
    }
}