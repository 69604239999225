.button {
    font-size:medium;
    font-weight: 400;
    width: 80px;
    height: 40px;
    background-color: #f3fcf0;
    font-weight: 500;
}

.button:hover {
    background-color: #d1fcc5;
    color: black;
    animation: buttonHover .5s;
}

@keyframes buttonHover {
    from { background-color: #f3fcf0; }
    to { background-color: #d1fcc5; }
}