.list_container {
    user-select: none;
    height: 100%;
    width: 30%;
    /* border: 1px dashed blue; */
    border-radius: 5px;
    background-color: rgb(67, 197, 197);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabs_container {
    width: 100%;
    margin-top: 5px;
}

.tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab {
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: white;
    margin-right: 5px;
}

.tab:hover {
    background-color: aliceblue;
    border: 1px solid #ccc;;
}

.active {
    padding: 10px 20px;
    border: 1px solid black;
    cursor: pointer;
    background-color: aliceblue;
    margin-right: 5px;
}

.account_content {
    overflow-y: auto;
    flex-grow: 1;
    width: 90%;
    display: flex;
    flex-direction: column;
    /* border: 1px dashed black; */
}

.address {
    user-select: none;
    font-size: 24px;
    font-weight: 500;
    
}

.address:hover {
    cursor: pointer;
    text-decoration: underline;
}

.address:focus {
    text-decoration: underline;
}