.header_container {
    /* border: 1px solid red; */
    width: 100%;
    height: 7vh;
    min-height: 50px;
    /* border-bottom: 4px solid #231F20; */
    display: flex;
    justify-content: flex-end;

    user-select: none;
    background-color: #256f24;

}

/* Anything tablet sized or larger */
@media (min-width: 768px) {
    .header_container{
        height: 7vh;
    }

    .header_content_container {
        /* border: 1px solid blue; */

        height: 100%;
        width: 95%;
        margin-right: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .dropdown_and_component_name_container {
        /* border: 1px solid red; */

        height: 100%;
        width: 1200px;

        position: relative;

        display: flex;
        justify-content: space-around;
        align-items: center;

    }
}
