.form_container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
}

.ui_message_container {
    background-color: #ffffff;
    margin-top: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header_container {
    text-align: center;
    margin-bottom: 20px;
}

.header {
    font-size: 24px;
    color: #333333;
}

.inputs_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.custom_inputs_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.label {
    font-weight: bold;
    color: #555555;
}

.radio_input {
    margin-right: 10px;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

.input:focus {
    border-color: #007bff;
    outline: none;
}

.button_container {
    text-align: center;
    margin-top: 20px;
}

.submit_button {
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit_button:hover {
    background-color: #4cae4c;
}
