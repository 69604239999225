.dropdown {
    min-width: 40px;
    min-height: 30px; 
    position: relative;
    cursor: pointer;

    z-index: 1;

    transform: rotate(0deg);
    transition: .5s ease-in-out;
}

.dropdown_bar {
    width: 100%;
    height: 4px;

    background-color: #f3fcf0; 
    border-radius: 3px;

    position: absolute;
    left: 0;

    transition: all 0.3s;
}

.dropdown_bar:nth-child(1) {
    top: 0; 
}
  
.dropdown_bar:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
}
  
.dropdown_bar:nth-child(3) {
    bottom: 0; 
}

.dropdown.active .dropdown_bar:nth-child(1) {
    transform: translateY(13px) rotate(45deg);
}
  
.dropdown.active .dropdown_bar:nth-child(2) {
    opacity: 0;
}
  
.dropdown.active .dropdown_bar:nth-child(3) {
    transform: translateY(-13px) rotate(-45deg);
}

.dropdown_menu {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0;
    list-style-type: none;
    background-color: #ffffff;
    border: 1px solid #888098;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1; 
    
}

.menu_item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;

    text-decoration: none;
    color: #333;

    transition: background-color 0.2s;


  
    &:hover {
        background-color: #888098;
        color: #231F20;
    }
}