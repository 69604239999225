.REPORT_GENERATION_container {
    width: 100%;
    height: 100%;
    display: flex;
    min-width: 1200px;
    min-height: 600px;
    justify-content: center;
    align-items: center;
    /* border: 1px dashed red; */
}

.REPORT_GENERATION_inner {
    height: 90%;
    width: 90%;
    /* border: 1px dashed blue; */
    display: flex;
    justify-content: space-between;

}

.report_selection_container {
    height: 100%;
    width: 30%;
    /* border: 1px dashed orange; */
    display: flex;
    flex-direction: column;
    background-color: rgb(67, 197, 197);
    border-radius: 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);


}

.tabs_container {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    /* border: 1px dashed rgb(97, 97, 28); */
}

.tab {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
    width: 20%;
    background-color: white;
}

.tab:hover {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
    width: 20%;
    background-color: aliceblue;
}

.active {
    width: 20%;
    background-color: aliceblue;
    border: 1px solid black;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
}

.active:hover {
    background-color: aliceblue;
    border: 1px solid black;
}

.forms_container {
    /* border: 1px dashed green; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.pdf_container {
    user-select: none;
    /* border: 1px dashed red; */
    display: flex;
    min-width: 800px;
    height: 100%;
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ccc;
    border-radius: 5px;
}