.container {
    height: 100%;
    width: 25%;
    background-color: #09520f;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    /* border: 1px dashed red; */
    border-radius: 10px;
    height: 90%;
    width: 100%;
    background-color: #018d0c;
    opacity: 100%;
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.075), 0 0 40px rgba(17, 51, 16, 0.6);
}