.form_container {
    height: 45%;
    width: 100%;
    border-radius: 5px;
    background-color: rgb(67, 197, 197);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);

    /* border: 1px dashed purple; */
}

.FORM_UPDATE_SERVICE_form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.header_container {
    width: 100%;
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
}

.header {
    font-size: 32px;
    text-decoration: underline;
    /* border: 1px solid red; */
}

.input_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* border: 1px solid red; */
}

.switch_container {
    width: 45%;
    /* border: 1px dashed red; */
    
}

.switch_label {
    margin-left: 5px;
    font-size: 24px;
    font-weight: 500;
    /* border: 1px dashed blue; */

}

.switch:hover {
    cursor: pointer;
}

.label {
    width: 45%;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid red; */
}

.input {
    width: 100%;
}

.select {
    width: 30%;
}

.select:hover {
    border: 2px solid blue;
    cursor: pointer;
}

.input:hover {
    background-color: aliceblue;
    width: 100%;
}

.button_container {
    height: 12%;
    width: 95%;
    /* border: 1px dashed blue; */
    display: flex;
    justify-content: center;
}

.submit_button {
    background-color: white;
    border: 1px solid black;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
}

.submit_button:hover {
    background-color: aliceblue;
    border: 1px solid black;
}